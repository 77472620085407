"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
var _default = exports.default = {
  /**
  * 分页查询协议信息
  */
  getAgreementList: function getAgreementList(data) {
    return (0, _http.GET)('/dict/findAgreementPageList', data);
  },
  /**
   * 新增协议信息
   */
  addAgreementInfo: function addAgreementInfo(data) {
    return (0, _http.FORMPOST)('/dict/addAgreementData', data);
  },
  /**
   * 查看协议信息
   */
  getAgreementInfo: function getAgreementInfo(data) {
    return (0, _http.GET)('/dict/getAgreementDetail', data);
  },
  /**
   * 编辑协议内容
   */
  updateAgreementInfo: function updateAgreementInfo(data) {
    return (0, _http.FORMPOST)('/dict/updateAgreementData', data);
  }
};