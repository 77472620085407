"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAuthorization = getAuthorization;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setAuthorization = setAuthorization;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("./index");
var TokenKey = 'Admin-Token';
var AuthKey = 'Authorization';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getAuthorization() {
  return (0, _index.getSession)(AuthKey);
}
function setAuthorization(auth) {
  return (0, _index.setSession)(AuthKey, auth);
}