"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _form = _interopRequireDefault(require("@/components/form"));
var _default = exports.default = {
  name: 'basicsManage',
  components: {
    formWrap: _form.default
  },
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '学校',
        prop: 'schoolName',
        minWidth: '100',
        width: null
      }, {
        title: '课程权限',
        prop: 'courseTitles',
        minWidth: '100',
        width: null
      }, {
        title: '成员数量',
        prop: 'userCount',
        minWidth: '',
        width: null
      }, {
        title: '操作',
        prop: 'operation',
        minWidth: '100',
        width: ''
      }],
      tableData: [],
      total: 0,
      searchParams: {
        currentPage: 1,
        pageSize: 20
      },
      id: '',
      title: ''
    };
  },
  mounted: function mounted() {
    this.getDataList();
    // 获取课程列表
    this.getCourseList();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {
        currentPage: this.searchParams.currentPage,
        pageSize: this.searchParams.pageSize,
        id: this.id
      };
      this.$api.basic.querySchoolCourseUserCountList(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    /* 获取列表数据 */getCourseList: function getCourseList() {
      var _this2 = this;
      var param = {
        currentPage: 1,
        pageSize: 999
      };
      this.$api.course.courseList(param).then(function (res) {
        _this2.courseList = res.data || [];
        if (_this2.courseList.length) {
          _this2.courseList.forEach(function (item) {
            _this2.$set(item, 'label', item.courseTitle);
            _this2.$set(item, 'value', item.id);
          });
        }
      });
    },
    /* 新增&编辑 */add: function add(type, obj) {
      var labelList = [{
        label: '学校名称',
        prop: 'schoolName',
        type: 'input',
        required: true
      }, {
        label: '课程权限',
        prop: 'coursePermissions',
        type: 'multipleSelect',
        required: false,
        select: this.courseList
      }];
      if (type === 'add') {
        this.title = '添加学校';
        this.$refs.form.showDialog(labelList);
      } else {
        this.title = '编辑学校';
        // 表单课程权限是字符串，所以转成数组
        if (!(obj.coursePermissions instanceof Array)) {
          this.$set(obj, 'coursePermissions', obj.coursePermissions.split(','));
        }
        obj.coursePermissions = obj.coursePermissions.map(function (item) {
          return Number(item);
        });
        this.$refs.form.showForm(obj, labelList);
      }
    },
    submit: function submit(form) {
      var _this3 = this;
      var coursePermissions = '';
      if (form.coursePermissions.length) {
        coursePermissions = form.coursePermissions.join(',');
        this.$set(form, 'coursePermissions', coursePermissions);
      } else {
        this.$set(form, 'coursePermissions', '');
      }
      if (form.id) {
        var param = {
          id: form.id,
          schoolName: form.schoolName,
          coursePermissions: form.coursePermissions
        };
        this.$api.basic.schoolEdit(param).then(function (res) {
          if (res.status.code === 200) {
            _this3.$message.success('编辑成功');
            _this3.$refs.form.cancel();
            _this3.getDataList();
          }
        });
      } else {
        this.$api.basic.schoolAdd(form).then(function (res) {
          if (res.status.code === 200) {
            _this3.$message.success('添加成功');
            _this3.$refs.form.cancel();
            _this3.getDataList();
          }
        });
      }
    },
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this4 = this;
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664".concat(obj.schoolName, "\u8FD9\u4E2A\u5B66\u6821\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.$api.basic.schoolDel({
          id: obj.id
        }).then(function () {
          _this4.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this4.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      this.getDataList();
    }
  }
};