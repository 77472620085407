"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar-logo-container",
    class: {
      collapse: _vm.collapse
    }
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_c("div", {
    staticClass: "sidebar-logo-link flex-box"
  }, [_c("img", {
    staticClass: "logo-img",
    attrs: {
      src: require("../../../../assets/images/pic_logo_60.png")
    }
  }), _c("span", {
    staticClass: "sidebar-title"
  }, [_vm._v("德盛在线课堂")])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;