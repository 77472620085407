"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// import { isExternal } from '@/utils/validate'
var _default = exports.default = {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    linkProps: function linkProps(url) {
      // if (isExternal(url)) {
      //   return {
      //     is: 'a',
      //     href: url,
      //     target: '_blank',
      //     rel: 'noopener'
      //   }
      // }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};