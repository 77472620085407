"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
var _default = exports.default = {
  /**
   * 评论列表
   */
  courseEvaluate: function courseEvaluate(data) {
    return (0, _http.POST)('/courseEvaluate/queryByPage', data);
  },
  /**
   * 评论列表——删除
   */
  evaluateDel: function evaluateDel(data) {
    return (0, _http.POST)("/courseEvaluate/deleteById?id=".concat(data.id), data);
  }
};