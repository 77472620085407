"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: {
      "has-logo": true
    }
  }, [true ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.activeMenu,
      "default-openeds": _vm.menuList,
      collapse: _vm.isCollapse,
      "unique-opened": false,
      "collapse-transition": false
    }
  }, _vm._l(_vm.permission_routes, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;