"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 管理后台菜单管理 */
var _default = exports.default = {
  /**
   * 菜单数据列表
   */
  getMenuListData: function getMenuListData(data) {
    return (0, _http.POST)('/menuManage/listMenuData', data);
  },
  /**
   * 菜单数据详情 （与列表合并）
  */
  getMenuDetail: function getMenuDetail(data) {
    return (0, _http.GET)('/menuManage/menuDataDetail', data);
  },
  /**
   * 保存(新增/修改)菜单
  */
  saveMenuData: function saveMenuData(data) {
    return (0, _http.POST)('/menuManage/saveMenuData', data);
  },
  /**
   * 删除菜单
  */
  deleteMenuData: function deleteMenuData(data) {
    return (0, _http.GET)('/menuManage/deleteMenuData', data);
  },
  /**
   * 项目指标字典表
  */
  projectMenuData: function projectMenuData(data) {
    return (0, _http.GET)('/menuManage/projectMenuData', data);
  },
  /**
   * 根据角色ID获取动态菜单
  */
  getCustomMenuByRoleId: function getCustomMenuByRoleId(data) {
    return (0, _http.GET)('/business/login/getCustomMenuByRoleId', data);
  }
};