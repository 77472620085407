"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("E:\\project\\desheng-manage-web\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("E:\\project\\desheng-manage-web\\node_modules\\core-js\\modules\\es6.promise.js");
require("E:\\project\\desheng-manage-web\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("E:\\project\\desheng-manage-web\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _App = _interopRequireDefault(require("./App.vue"));
var _elementUi = _interopRequireWildcard(require("element-ui"));
var _tooltip = _interopRequireDefault(require("tooltip.js"));
var _clipboard = _interopRequireDefault(require("clipboard/dist/clipboard"));
var _moment = _interopRequireDefault(require("moment"));
var _index = _interopRequireDefault(require("@/components/index"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
var _common = _interopRequireDefault(require("./utils/common.js"));
require("@/api");
var _wangeditor = _interopRequireDefault(require("wangeditor"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// icon
// permission control

// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') { mockXHR() }
// import JsonSchemaEditor from 'vue-json-schema-editor-visual';

// Vue.use(JsonSchemaEditor)

_vue.default.use(_elementUi.default);
// Vue.use(Message)
_vue.default.use(_index.default);
_vue.default.config.productionTip = false;
_vue.default.prototype.Moment = _moment.default;
_vue.default.prototype.Tooltip = _tooltip.default;
_vue.default.prototype.$Message = _elementUi.Message;
_vue.default.prototype.Copy = _clipboard.default;
_vue.default.prototype.Common = _common.default;
_vue.default.prototype.wangEditor = _wangeditor.default;

// 创建全局EventBus
var EventBus = new _vue.default();
Object.defineProperties(_vue.default.prototype, {
  $bus: {
    get: function get() {
      return EventBus;
    }
  }
});
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');