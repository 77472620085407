"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
var _default = exports.default = {
  name: 'login',
  data: function data() {
    var checkphone = function checkphone(rule, value, callback) {
      var phoneReg = /^1\d{10}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      activeTap: 'pwd',
      verification: false,
      timer: 60,
      img: '',
      pwdFormData: {
        //projectId: this.$store.state.user.projectId,

        // loginName: getSession('LOGIN_NAME') ? getSession('LOGIN_NAME') : 'admin',
        // loginPwd: getSession('LOGIN_PWD') ? getSession('LOGIN_PWD') : 'dbn002385',

        loginName: '',
        loginPwd: ''
        // eslint-disable-next-line no-undef
        // ip: returnCitySN.cip
      },
      smsFormData: {
        loginName: '',
        smsPwd: '',
        tempToken: ''
        // eslint-disable-next-line no-undef
        // ip: returnCitySN.cip
      },
      pwdRules: {
        loginName: [{
          required: true,
          message: '请输入账户/手机/邮箱',
          trigger: 'blur'
        }],
        loginPwd: [{
          required: true,
          message: '请输入登录密码',
          trigger: 'blur'
        }]
      },
      smsRules: {
        loginName: [{
          required: true,
          validator: checkphone,
          trigger: 'blur'
        }],
        smsPwd: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }]
      },
      redirect: '',
      clickCount: 0,
      checkpwd: (0, _index.getSession)('REMENBER_PWD') ? true : false
    };
  },
  created: function created() {
    //无登录
    // this.$store.dispatch('login', this.pwdFormData).then(() => {
    //   if (getSession('REMENBER_PWD')) {
    //     setSession('LOGIN_NAME', this.pwdFormData.loginName)
    //     setSession('LOGIN_PWD', this.pwdFormData.loginPwd)
    //   }
    //   this.clickCount = 0
    //   this.$router.push({ path: this.redirect || '/' })
    // }).catch(() => {
    //   this.clickCount = 0
    // })
  },
  mounted: function mounted() {
    var redirect = this.$route.query.redirect || '';
    this.redirect = window.atob(redirect);
  },
  watch: {
    'checkpwd': function checkpwd(val) {
      if (val) {
        localStorage.setItem('REMENBER_PWD', true);
      } else {
        localStorage.removeItem('REMENBER_PWD');
        localStorage.removeItem('LOGIN_NAME');
        localStorage.removeItem('LOGIN_PWD');
      }
    }
  },
  methods: {
    handleTab: function handleTab() {
      console.log(this.activeTap);
      this.clickCount = 0;
    },
    forgetPwd: function forgetPwd() {
      this.$router.push('/resetpwd');
    },
    /* 发送短信验证码 */sendVerificationCode: function sendVerificationCode(formName) {
      var _this = this;
      if (this.clickCount) return false;
      this.$refs[formName].validateField('loginName', function (errMsg) {
        if (errMsg) {
          _this.clickCount = 0;
          return false;
        } else {
          _this.clickCount++;
          _this.$api.business.sendSms({
            //projectId: this.$store.state.user.projectId,
            loginName: _this.smsFormData.loginName
          }).then(function (res) {
            _this.smsFormData.tempToken = res.data.tempToken;
            _this.$message({
              message: '验证码发送成功',
              type: 'success'
            });
            _this.timer = 60;
            _this.verification = true;
            _this.clickCount = 0;
            var auth_timer = setInterval(function () {
              //定时器设置每秒递减
              _this.timer--;
              if (_this.timer === 0) {
                _this.verification = false;
                clearInterval(auth_timer);
              }
            }, 1000);
          });
        }
      });
    },
    /* 账号密码登录 */pwdSubmitForm: function pwdSubmitForm(formName) {
      var _this2 = this;
      // this.$router.push({ path: this.redirect || '/basicsManage/index' })
      if (this.clickCount) return false;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.clickCount++;
          _this2.$store.dispatch('login', _this2.pwdFormData).then(function () {
            if ((0, _index.getSession)('REMENBER_PWD')) {
              (0, _index.setSession)('LOGIN_NAME', _this2.pwdFormData.loginName);
              (0, _index.setSession)('LOGIN_PWD', _this2.pwdFormData.loginPwd);
            }
            _this2.$message({
              message: '登录成功',
              type: 'success'
            });
            _this2.clickCount = 0;
            _this2.$router.push({
              path: _this2.redirect || '/basicsManage/index'
            });
          }).catch(function () {
            _this2.clickCount = 0;
          });
        } else {
          _this2.clickCount = 0;
          return false;
        }
      });
    },
    /* 短信登录 */smsSubmitForm: function smsSubmitForm(formName) {
      var _this3 = this;
      if (this.clickCount) return false;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var _this3$smsFormData = _this3.smsFormData,
            tempToken = _this3$smsFormData.tempToken,
            smsPwd = _this3$smsFormData.smsPwd,
            ip = _this3$smsFormData.ip;
          _this3.clickCount++;
          _this3.$store.dispatch('smsLogin', {
            //projectId: this.$store.state.user.projectId,
            tempToken: tempToken,
            smsPwd: smsPwd,
            ip: ip
          }).then(function () {
            _this3.$message({
              message: '登录成功',
              type: 'success'
            });
            _this3.clickCount = 0;
            _this3.$router.push({
              path: _this3.redirect || '/'
            });
          }).catch(function () {
            _this3.clickCount = 0;
          });
        } else {
          _this3.clickCount = 0;
          return false;
        }
      });
    }
  }
};