"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
var _default = exports.default = {
  //课程管理
  getCourseByPage: function getCourseByPage(data) {
    // 获取课程管理分页列表
    return (0, _http.GET)('/course/page', data);
  },
  getCourseById: function getCourseById(data) {
    // 获取课程详情
    return (0, _http.GET)('/course/getById', data);
  },
  addCourse: function addCourse(data) {
    //新增课程
    return (0, _http.POST)('/course/save', data);
  },
  updateCourse: function updateCourse(data) {
    //修改课程
    return (0, _http.POST)('/course/updateById', data);
  },
  delCourse: function delCourse(id) {
    // 删除课程
    return (0, _http.POST)("/course/deleteById?id=".concat(id));
  },
  pushCourse: function pushCourse(id) {
    // 发布课程
    return (0, _http.POST)("/course/push?id=".concat(id));
  },
  onCourse: function onCourse(id) {
    // 上架课程
    return (0, _http.POST)("/course/on?id=".concat(id));
  },
  offCourse: function offCourse(id) {
    // 下架课程
    return (0, _http.POST)("/course/off?id=".concat(id));
  },
  getLecturerDropDownList: function getLecturerDropDownList(data) {
    // 获取讲师下拉列表
    return (0, _http.GET)('/course/teacherList', data);
  },
  //讲师管理
  getLecturerByPage: function getLecturerByPage(data) {
    // 获取讲师管理分页列表
    return (0, _http.POST)('/xhcLecturer/queryByPage', data);
  },
  getLecturerById: function getLecturerById(data) {
    // 根据Id获取讲师信息
    return (0, _http.GET)('/xhcLecturer/queryById', data);
  },
  addLecturer: function addLecturer(data) {
    // 新增讲师
    return (0, _http.POST)('/xhcLecturer/insert', data);
  },
  updateLecturer: function updateLecturer(data) {
    // 更新讲师
    return (0, _http.POST)('/xhcLecturer/update', data);
  },
  delLecturer: function delLecturer(data) {
    // 删除讲师
    return (0, _http.GET)('/xhcLecturer/deleteById', data);
  },
  //培训管理
  getDrillInfoList: function getDrillInfoList(data) {
    // 获取培训管理分页列表
    return (0, _http.POST)('/xhc/drillInfo/getDrillInfoList', data);
  },
  getDrillInfoById: function getDrillInfoById(data) {
    // 获取培训详情
    return (0, _http.GET)('/xhc/drillInfo/getDrillInfoById', data);
  },
  getUserByDrillInfoId: function getUserByDrillInfoId(data) {
    // 获取培训任务学员详情
    return (0, _http.POST)('/xhc/drillInfo//getUserByDrillInfoId', data);
  },
  getCourseList: function getCourseList(data) {
    // 获取课程列表
    return (0, _http.GET)('/xhc/drillInfo/getCourseList', data);
  },
  getGovDepList: function getGovDepList(data) {
    // 获取部门列表
    return (0, _http.GET)('/xhc/drillInfo/getGovDepList', data);
  },
  getUserByDepId: function getUserByDepId(data) {
    // 获取部门成员列表
    return (0, _http.POST)('/xhc/drillInfo/getUserByDepId', data);
  },
  addDrillInfo: function addDrillInfo(data) {
    // 新增培训
    return (0, _http.POST)('/xhc/drillInfo/addDrillInfo', data);
  },
  delDrillInfo: function delDrillInfo(data) {
    // 删除培训
    return (0, _http.GET)('/xhc/drillInfo/delDrillInfo', data);
  },
  publishDrillInfo: function publishDrillInfo(data) {
    // 发布培训
    return (0, _http.GET)('/xhc/drillInfo/publishDrillInfo', data);
  }
};