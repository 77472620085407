"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.noFilterParamRequest = exports.POST = exports.JSONPOST = exports.JSONGET = exports.GET = exports.FORMPOST = exports.FILEPOST = void 0;
require("core-js/modules/es6.regexp.replace");
var _axios = _interopRequireDefault(require("axios"));
var _qs = _interopRequireDefault(require("qs"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _router = _interopRequireDefault(require("../router"));
var errorCount = 0;
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  //withCredentials: true, // 跨域请求时发送 cookies, default: false
  timeout: 10000,
  // request timeout
  responseType: 'json',
  // default
  maxContentLength: 2000,
  maxRedirects: 5,
  // default
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: function paramsSerializer(params) {
    return _qs.default.stringify(params, {
      arrayFormat: 'brackets'
    });
  }
});

// request interceptor
service.interceptors.request.use(function (config) {
  // Do something before request is sent
  // if (store.getters.token) {
  // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  config.headers['Authorization'] = (0, _auth.getAuthorization)();
  config.headers['token'] = (0, _auth.getToken)();
  if (config['headers']['Content-Type'] === 'application/x-www-form-urlencoded') {
    config['data'] = _qs.default.stringify(config['data']);
  }
  // }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
*/
/**
 * 下面的注释为通过在response里，自定义code来标示请求状态
 * 当code返回如下情况则说明权限有问题，登出并返回到登录页
 * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
 * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
 */
function (response) {
  var res = response.data;
  var status = res.status;
  var code = res.code,
    msg = res.msg;
  code = code || status.code;
  msg = msg || status.msg;
  // code = 200
  // msg = ''
  if (code !== 200) {
    // if (code === 304) {
    //   if (!errorCount){
    //     errorCount ++
    //     MessageBox.confirm('您已被登出，请重新登录', '确定登出', {
    //       confirmButtonText: '重新登录',
    //       showCancelButton: false,
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('logout').then(() => {
    //         errorCount = 0
    //         location.reload() // 为了重新实例化vue-router对象 避免bug
    //       })
    //     })
    //   }
    // }
    if (code === 304 || code === 305 || code === 306) {
      // if (msg === '解析token失败,未知错误' || msg === 'token已过期') {

      if (!errorCount) {
        errorCount++;
        _elementUi.MessageBox.confirm('您的登录信息已经失效，请重新登录', '提示', {
          confirmButtonText: '重新登录',
          showCancelButton: false,
          type: 'warning',
          showClose: false
        }).then(function () {
          _store.default.dispatch('logout');
          _router.default.replace({
            path: '/login'
          });
          errorCount = 0;
          // location.reload() // 为了重新实例化vue-router对象 避免bug
          // store.dispatch('logout').then(() => {
          //   errorCount = 0
          //   location.reload() // 为了重新实例化vue-router对象 避免bug
          // })
        });
      }

      // } else {
      //   Message({
      //     message: msg || 'error',
      //     type: 'error',
      //     duration: 5 * 1000
      //   })
      // }
    } else {
      if (msg !== '请联系管理员分配权限') {
        (0, _elementUi.Message)({
          message: msg || 'error',
          type: 'error',
          duration: 5 * 1000
        });
      } else {
        (0, _elementUi.MessageBox)({
          title: '提示',
          message: '【请联系系统管理员分配权限】',
          closeOnClickModal: false,
          confirmButtonText: '确定'
        });
      }
    }
    return Promise.reject(msg || 'error');
  } else {
    return res;
  }
}, function (error) {
  if (error.code === 'ECONNABORTED') {
    // 请求超时
    (0, _elementUi.Message)({
      message: '请求超时，请刷新页面再次请求',
      type: 'error',
      duration: 5 * 1000
    });
  } else if (error.message === 'Network Error') {
    (0, _elementUi.Message)({
      message: '网络错误，请检查您的网络',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    (0, _elementUi.Message)({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});

/**
 *  过滤value为空的参数
 * @param obj
 */
function filterParam(obj) {
  var paramObj = {};
  for (var i in obj) {
    if (obj[i] !== '' && obj[i] !== undefined) {
      paramObj[i] = obj[i];
    }
  }
  if (paramObj.hasOwnProperty('areaCode') && Array.isArray(paramObj.areaCode) && paramObj.areaCode[0] === '') {
    delete paramObj['areaCode'];
  }
  return paramObj;
}
/**
 * 过滤undefined的参数
 */
function filterUndefinedParam(obj) {
  var paramObj = {};
  for (var i in obj) {
    if (obj[i] !== undefined) {
      paramObj[i] = obj[i];
    }
  }
  if (paramObj.hasOwnProperty('areaCode') && Array.isArray(paramObj.areaCode) && paramObj.areaCode[0] === '') {
    delete paramObj['areaCode'];
  }
  return paramObj;
}
var POST = exports.POST = function POST(url, data, filter) {
  var paramObj = {};
  if (filter === 'undefined') {
    paramObj = filterUndefinedParam(data);
  } else if (filter === 'none') {
    paramObj = data;
  } else {
    paramObj = filterParam(data);
  }
  return service({
    url: url,
    method: 'post',
    data: paramObj
  });
};
var FILEPOST = exports.FILEPOST = function FILEPOST(url, data) {
  return service({
    baseURL: process.env.VUE_APP_BASE_API,
    url: url,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 0
  });
};
var GET = exports.GET = function GET(url, query, filter) {
  var paramObj = {};
  if (filter === 'undefined') {
    paramObj = filterUndefinedParam(query);
  } else {
    paramObj = filterParam(query);
  }
  return service({
    url: url,
    method: 'get',
    params: paramObj
  });
};
var noFilterParamRequest = exports.noFilterParamRequest = function noFilterParamRequest(url, config) {
  return service({
    url: url,
    method: config.method,
    params: config.params,
    data: config.params
  });
};
var JSONGET = exports.JSONGET = function JSONGET(url, data, filter) {
  var paramObj = {};
  if (filter === 'undefined') {
    paramObj = filterUndefinedParam(data);
  } else {
    paramObj = filterParam(data);
  }
  return service({
    url: url,
    method: 'GET',
    params: paramObj,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 0
  });
};
var JSONPOST = exports.JSONPOST = function JSONPOST(url, data, filter) {
  var paramObj = {};
  if (filter === 'undefined') {
    paramObj = filterUndefinedParam(data);
  } else {
    paramObj = filterParam(data);
  }
  return service({
    url: url,
    method: 'post',
    data: paramObj,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 0
  });
};
var FORMPOST = exports.FORMPOST = function FORMPOST(url, data, filter) {
  var paramObj = {};
  if (filter === 'undefined') {
    paramObj = filterUndefinedParam(data);
  } else {
    paramObj = filterParam(data);
  }
  return service({
    url: url,
    method: 'post',
    data: paramObj,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 0
  });
};