"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/**
 * 溯源管理接口
 * huangjianhong
 * 2023/5/26
 */
var _default = exports.default = {
  /**
   * 获取搜索栏企业下拉框列表
   */
  getEnterpriseSelectList: function getEnterpriseSelectList(data) {
    return (0, _http.GET)('/xhc/enterpriseInfo/getEnterpriseSelectList', data);
  },
  /**
   * 获取搜索栏追溯码下拉框（批次）
   */
  getBasicRetraceSelectList: function getBasicRetraceSelectList(data) {
    return (0, _http.GET)('/xhc/basicRetrace/getBasicRetraceSelectList', data);
  },
  /**
   * 获取雏鸡入场企业追溯码下拉框（批次）
   */
  enterBatchSelect: function enterBatchSelect(data) {
    return (0, _http.GET)('/xhc/chickenEnter/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取抗体检测企业追溯码下拉框（批次）
   */
  antigenDectectionBatchSelect: function antigenDectectionBatchSelect(data) {
    return (0, _http.GET)('/xhc/chickenAntigen/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取饲喂记录企业追溯码下拉框（批次）
   */
  feedingBatchSelect: function feedingBatchSelect(data) {
    return (0, _http.GET)('/xhc/chickenFeeding/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取免疫企业追溯码下拉框（批次）
   */
  immunityBatchSelect: function immunityBatchSelect(data) {
    return (0, _http.GET)('/xhc/chickenImmunity/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取用药企业追溯码下拉框（批次）
   */
  pharmacyBatchSelect: function pharmacyBatchSelect(data) {
    return (0, _http.GET)('/xhc/chickenPharmacy/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取检疫管理企业追溯码下拉框（批次）
   */
  quarantineManageBatchSelect: function quarantineManageBatchSelect(data) {
    return (0, _http.GET)('/xhc/quarantineInfo/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取物流管理企业追溯码下拉框（批次）
   */
  logisticsManageBatchSelect: function logisticsManageBatchSelect(data) {
    return (0, _http.GET)('/xhc/logisticsInfo/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取屠宰管理企业追溯码下拉框（批次）
   */
  slaughterManageBatchSelect: function slaughterManageBatchSelect(data) {
    return (0, _http.GET)('/xhc/slaughteringInfo/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取小鸡入场信息列表
   */
  enterTable: function enterTable(data) {
    return (0, _http.POST)('/xhc/chickenEnter/getChickenEnterList', data);
  },
  /**
   * 获取入场批次下拉框列表
   */
  getChickBatch: function getChickBatch(data) {
    return (0, _http.GET)('/xhc/chickenEnter/getBasicRetraceAndEnterprise', data);
  },
  /**
   * 获取雏鸡入场详情
   */
  enterDetail: function enterDetail(data) {
    return (0, _http.GET)('/xhc/chickenEnter/getChickenEnterById', data);
  },
  /**
   * 获取抗原检测详情
   */
  antigenDectectionDetail: function antigenDectectionDetail(data) {
    return (0, _http.GET)('/xhc/chickenAntigen/getChickenAntigenById', data);
  },
  /**
   * 获取饲喂记录详情
   */
  feedingDetail: function feedingDetail(data) {
    return (0, _http.GET)('/xhc/chickenFeeding/getChickenFeedingById', data);
  },
  /**
   * 获取免疫详情
   */
  immunityDetail: function immunityDetail(data) {
    return (0, _http.GET)('/xhc/chickenImmunity/getChickenImmunityById', data);
  },
  /**
   * 获取用药详情
   */
  pharmacyDetail: function pharmacyDetail(data) {
    return (0, _http.GET)('/xhc/chickenPharmacy/getChickenPharmacyById', data);
  },
  /**
   * 获取检疫管理详情
   */
  quarantineManageDetail: function quarantineManageDetail(data) {
    return (0, _http.GET)('/xhc/quarantineInfo/getQuarantineInfoById', data);
  },
  /**
   * 获取物流管理详情
   */
  logisticsManageDetail: function logisticsManageDetail(data) {
    return (0, _http.GET)('/xhc/logisticsInfo/getLogisticsInfoById', data);
  },
  /**
   * 获取屠宰管理详情
   */
  slaughterManageDetail: function slaughterManageDetail(data) {
    return (0, _http.GET)('/xhc/slaughteringInfo/getSlaughteringInfoById', data);
  },
  /**
   * 获取抗体检测信息列表
   */
  antigenDectectionTable: function antigenDectectionTable(data) {
    return (0, _http.POST)('/xhc/chickenAntigen/getChickenAntigenList', data);
  },
  /**
   * 获取饲喂记录列表
   */
  feedingTable: function feedingTable(data) {
    return (0, _http.POST)('/xhc/chickenFeeding/getChickenFeedingList', data);
  },
  /**
   * 获取免疫列表
   */
  immunityTable: function immunityTable(data) {
    return (0, _http.POST)('/xhc/chickenImmunity/getChickenImmunityList', data);
  },
  /**
   * 获取用药列表
   */
  pharmacyTable: function pharmacyTable(data) {
    return (0, _http.POST)('/xhc/chickenPharmacy/getChickenPharmacyList', data);
  },
  /**
   * 获取检疫管理列表
   */
  quarantineManageTable: function quarantineManageTable(data) {
    return (0, _http.POST)('/xhc/quarantineInfo/getQuarantineInfoList', data);
  },
  /**
   * 获取物流管理列表
   */
  logisticsManageTable: function logisticsManageTable(data) {
    return (0, _http.POST)('/xhc/logisticsInfo/getLogisticsInfoList', data);
  },
  /**
   * 获取屠宰管理列表
   */
  slaughterManageTable: function slaughterManageTable(data) {
    return (0, _http.POST)('/xhc/slaughteringInfo/getSlaughteringInfoList', data);
  },
  /**
   * 小鸡入场新增
   */
  enterAdd: function enterAdd(data) {
    return (0, _http.POST)('/xhc/chickenEnter/addChickenEnter', data);
  },
  /**
   * 抗体检测新增
   */
  antigenDectectionAdd: function antigenDectectionAdd(data) {
    return (0, _http.POST)('/xhc/chickenAntigen/addChickenAntigen', data);
  },
  /**
   * 饲喂记录新增
   */
  feedingAdd: function feedingAdd(data) {
    return (0, _http.POST)('/xhc/chickenFeeding/addChickenFeeding', data);
  },
  /**
   * 免疫新增
   */
  immunityAdd: function immunityAdd(data) {
    return (0, _http.POST)('/xhc/chickenImmunity/addChickenImmunity', data);
  },
  /**
   * 用药新增
   */
  pharmacyAdd: function pharmacyAdd(data) {
    return (0, _http.POST)('/xhc/chickenPharmacy/addChickenPharmacy', data);
  },
  /**
   * 检疫管理新增
   */
  quarantineManageAdd: function quarantineManageAdd(data) {
    return (0, _http.POST)('/xhc/quarantineInfo/addQuarantine', data);
  },
  /**
   * 物流管理新增
   */
  logisticsManageAdd: function logisticsManageAdd(data) {
    return (0, _http.POST)('/xhc/logisticsInfo/addLogistics', data);
  },
  /**
   * 屠宰管理新增
   */
  slaughterManageAdd: function slaughterManageAdd(data) {
    return (0, _http.POST)('/xhc/slaughteringInfo/addSlaughtering', data);
  },
  /**
   * 雏鸡入场删除
   */
  enterDelete: function enterDelete(data) {
    return (0, _http.GET)('/xhc/chickenEnter/deleteChickenEnter', data);
  },
  /**
   * 抗体检测删除
   */
  antigenDectectionDelete: function antigenDectectionDelete(data) {
    return (0, _http.GET)('/xhc/chickenAntigen/deleteChickenAntigen', data);
  },
  /**
   * 饲喂记录删除
   */
  feedingDelete: function feedingDelete(data) {
    return (0, _http.GET)('/xhc/chickenFeeding/deleteChickenFeeding', data);
  },
  /**
   * 免疫删除
   */
  immunityDelete: function immunityDelete(data) {
    return (0, _http.GET)('/xhc/chickenImmunity/deleteChickenImmunity', data);
  },
  /**
   * 用药删除
   */
  pharmacyDelete: function pharmacyDelete(data) {
    return (0, _http.GET)('/xhc/chickenPharmacy/deleteChickenPharmacy', data);
  },
  /**
   * 检疫管理删除
   */
  quarantineManageDelete: function quarantineManageDelete(data) {
    return (0, _http.GET)('/xhc/quarantineInfo/deleteQuarantine', data);
  },
  /**
   * 物流管理删除
   */
  logisticsManageDelete: function logisticsManageDelete(data) {
    return (0, _http.GET)('/xhc/logisticsInfo/deleteLogistics', data);
  },
  /**
   * 屠宰管理删除
   */
  slaughterManageDelete: function slaughterManageDelete(data) {
    return (0, _http.GET)('/xhc/slaughteringInfo/deleteSlaughtering', data);
  },
  /**
   * 小鸡入场编辑
   */
  enterEdit: function enterEdit(data) {
    return (0, _http.POST)('/xhc/chickenEnter/updateChickenEnter', data);
  },
  /**
   * 抗体检测编辑
   */
  antigenDectectionEdit: function antigenDectectionEdit(data) {
    return (0, _http.POST)('/xhc/chickenAntigen/updateChickenAntigen', data);
  },
  /**
   * 饲喂记录编辑
   */
  feedingEdit: function feedingEdit(data) {
    return (0, _http.POST)('/xhc/chickenFeeding/updateChickenFeeding', data);
  },
  /**
   * 免疫编辑
   */
  immunityEdit: function immunityEdit(data) {
    return (0, _http.POST)('/xhc/chickenImmunity/updateChickenImmunity', data);
  },
  /**
   * 用药编辑
   */
  pharmacyEdit: function pharmacyEdit(data) {
    return (0, _http.POST)('/xhc/chickenPharmacy/updateChickenPharmacy', data);
  },
  /**
   * 检疫管理编辑
   */
  quarantineManageEdit: function quarantineManageEdit(data) {
    return (0, _http.POST)('/xhc/quarantineInfo/updateQuarantine', data);
  },
  /**
   * 物流管理编辑
   */
  logisticsManageEdit: function logisticsManageEdit(data) {
    return (0, _http.POST)('/xhc/logisticsInfo/updateLogistics', data);
  },
  /**
   * 屠宰管理编辑
   */
  slaughterManageEdit: function slaughterManageEdit(data) {
    return (0, _http.POST)('/xhc/slaughteringInfo/updateSlaughtering', data);
  }
};