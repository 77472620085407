"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _verification = require("@/utils/verification");
var _utils = require("@/utils");
var _jsencrypt = require("jsencrypt");
// import { cloneDeep } from 'lodash'
var _default = exports.default = {
  name: 'login',
  data: function data() {
    var _this = this;
    var checkphone = function checkphone(rule, value, callback) {
      var phoneReg = /^1\d{10}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    var checkConfirmPwd = function checkConfirmPwd(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入登录密码!'));
      } else if (value !== _this.pwdFormData.loginPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      activeName: 'pwd',
      verification: false,
      timer: 60,
      img: '',
      pwdFormData: {
        loginName: '',
        smsPwd: '',
        loginPwd: '',
        loginPwds: '',
        tempToken: ''
      },
      pwdRules: {
        loginName: [{
          required: true,
          validator: checkphone,
          trigger: 'blur'
        }],
        smsPwd: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }],
        loginPwd: [{
          required: true,
          message: '登录密码不能为空',
          trigger: 'blur'
        }, {
          pattern: _verification.REG.passWord,
          message: _verification.TEXT_MESSAGE.passWord,
          trigger: 'blur'
        }],
        loginPwds: [{
          required: true,
          validator: checkConfirmPwd,
          trigger: 'blur'
        }]
      },
      redirect: '',
      clickCount: 0,
      checkpwd: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var redirect = this.$route.query.redirect || '';
    this.redirect = window.atob(redirect);
  },
  methods: {
    /* 发送短信验证码 */sendVerificationCode: function sendVerificationCode(formName) {
      var _this2 = this;
      if (this.clickCount) return false;
      this.$refs[formName].validateField('loginName', function (errMsg) {
        if (errMsg) {
          _this2.clickCount = 0;
          return false;
        } else {
          _this2.clickCount++;
          _this2.$api.business.sendSms({
            //projectId: this.$store.state.user.projectId,
            loginName: _this2.pwdFormData.loginName
          }).then(function (res) {
            _this2.pwdFormData.tempToken = res.data.tempToken;
            _this2.$message({
              message: '验证码发送成功',
              type: 'success'
            });
            _this2.timer = 60;
            _this2.verification = true;
            _this2.clickCount = 0;
            var auth_timer = setInterval(function () {
              //定时器设置每秒递减
              _this2.timer--;
              if (_this2.timer === 0) {
                _this2.verification = false;
                clearInterval(auth_timer);
              }
            }, 1000);
          });
        }
      });
    },
    /* 重置 */resetSubmitForm: function resetSubmitForm(formName) {
      var _this3 = this;
      if (this.clickCount) return false;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var _this3$pwdFormData = _this3.pwdFormData,
            smsPwd = _this3$pwdFormData.smsPwd,
            loginPwd = _this3$pwdFormData.loginPwd,
            tempToken = _this3$pwdFormData.tempToken;
          var key = (0, _utils.publicKey)();
          var encryptor = new _jsencrypt.JSEncrypt();
          encryptor.setPublicKey(key);
          var encrypted = encryptor.encrypt(loginPwd);
          _this3.clickCount++;
          _this3.$api.business.smsResetPwd({
            //projectId: this.$store.state.user.projectId,
            smsPwd: smsPwd,
            tempToken: tempToken,
            loginPwd: encrypted
          }).then(function (res) {
            console.log(res);
            _this3.$message({
              message: '密码重置成功',
              type: 'success'
            });
            setTimeout(function () {
              _this3.$router.push('/login');
            }, 1000);
          }).catch(function () {
            _this3.clickCount = 0;
          });
        } else {
          _this3.clickCount = 0;
          return false;
        }
      });
    }
  }
};