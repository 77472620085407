"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
// 数据管理
var _default = exports.default = {
  /**
   * 企业管理-分页查询企业列表
   */
  getListEnterpriseInfo: function getListEnterpriseInfo(data) {
    return (0, _http.POST)('/enterprise/listEnterpriseInfo', data);
  },
  /**
   * 企业管理-查看企业详情
   */
  getEnterpriseInfo: function getEnterpriseInfo(data) {
    return (0, _http.GET)('/enterprise/getEnterpriseInfo', data);
  },
  /**
   * 企业管理-保存(新增/修改)企业
   */
  saveEnterpriseInfo: function saveEnterpriseInfo(data) {
    return (0, _http.POST)('/enterprise/saveEnterpriseInfo', data);
  },
  /**
  * 企业管理-获取中台企业列表(绑定设备中台企业列表)
  */
  getIotEnterpriseList: function getIotEnterpriseList(data) {
    return (0, _http.POST)('/enterprise/getIotEnterpriseList', data);
  },
  /**
   * 企业管理-绑定/解绑/删除企业
   */
  operateEnterpriseInfo: function operateEnterpriseInfo(data) {
    return (0, _http.POST)('/enterprise/operateEnterpriseInfo', data);
  },
  /**
   * 企业管理-导入(excel)企业
   */
  importEnterpriseInfo: function importEnterpriseInfo(data) {
    return (0, _http.FILEPOST)('/enterprise/importEnterpriseInfo', data);
  },
  /**
   * 投入品数据管理列表
   */
  getListInputsIndexData: function getListInputsIndexData(data) {
    return (0, _http.POST)('/indexData/listInputsIndexData', data);
  },
  /**
   * 保存(新增/修改) 投入品数据管理
   */
  saveInputsIndexData: function saveInputsIndexData(data) {
    return (0, _http.POST)('/indexData/saveInputsIndexData', data);
  },
  /**
   * 删除 投入品数据管理
   */
  deleteInputsIndexData: function deleteInputsIndexData(data) {
    return (0, _http.POST)('/indexData/deleteInputsIndexData', data);
  },
  /**
   * 数据指标-导入(excel)
   */
  uploadInputsIndexData: function uploadInputsIndexData(data) {
    return (0, _http.FILEPOST)('/indexData/uploadInputsIndexData', data);
  },
  /**
   * 数据指标-导出(excel)
   */
  importInputsIndexData: function importInputsIndexData(data) {
    return (0, _http.POST)('/indexData/importInputsIndexData', data);
  },
  /**
   * 杏花鸡屠宰查询
   */
  getPigButcherInfo: function getPigButcherInfo(data) {
    return (0, _http.GET)('/pigButcher/getPigButcherInfo', data);
  },
  /**
   * 杏花鸡屠宰分页
   */
  listPigButcherInfo: function listPigButcherInfo(data) {
    return (0, _http.POST)('/pigButcher/findList', data);
  },
  /**
   * 杏花鸡屠宰新增
   */
  insertPigButcherInfo: function insertPigButcherInfo(data) {
    return (0, _http.POST)('/pigButcher/insertPigButcherInfo', data);
  },
  /**
   * 杏花鸡屠宰修改
   */
  updatePigButcherInfo: function updatePigButcherInfo(data) {
    return (0, _http.POST)('/pigButcher/updatePigButcherInfo', data);
  },
  /**
   * 杏花鸡屠宰删除
   */
  deletePigButcherInfo: function deletePigButcherInfo(data) {
    return (0, _http.POST)("/pigButcher/deletePigButcherInfo?id=".concat(data.id), data);
  },
  /**
   * 杏花鸡屠宰导入
   */
  importPigButcherInfo: function importPigButcherInfo(data) {
    return (0, _http.FILEPOST)('/pigButcher/importPigButcherInfo', data);
  },
  /**
   * 无害化处理查询
   */
  getPigHarmlessTreatmentInfo: function getPigHarmlessTreatmentInfo(data) {
    return (0, _http.GET)('/pigHarmlessTreatment/getPigHarmlessTreatmentInfo', data);
  },
  /**
   * 无害化处理分页
   */
  listPigHarmlessTreatmentInfo: function listPigHarmlessTreatmentInfo(data) {
    return (0, _http.POST)('/pigHarmlessTreatment/findList', data);
  },
  /**
   * 无害化处理新增
   */
  insertPigHarmlessTreatmentInfo: function insertPigHarmlessTreatmentInfo(data) {
    return (0, _http.POST)('/pigHarmlessTreatment/insertPigHarmlessTreatmentInfo', data);
  },
  /**
   * 无害化处理修改
   */
  updatePigHarmlessTreatmentInfo: function updatePigHarmlessTreatmentInfo(data) {
    return (0, _http.POST)('/pigHarmlessTreatment/updatePigHarmlessTreatmentInfo', data);
  },
  /**
   * 无害化处理删除
   */
  deletePigHarmlessTreatmentInfo: function deletePigHarmlessTreatmentInfo(data) {
    return (0, _http.POST)("/pigHarmlessTreatment/deletePigHarmlessTreatmentInfo?id=".concat(data.id), data);
  },
  /**
   * 无害化处理导入
   */
  importPigHarmlessTreatmentInfo: function importPigHarmlessTreatmentInfo(data) {
    return (0, _http.FILEPOST)('/pigHarmlessTreatment/importPigHarmlessTreatmentInfo', data);
  },
  /**
   * 获取省市区信息
   */
  getAreaAll: function getAreaAll(data) {
    return (0, _http.GET)('/common/getAreaAll', data);
  },
  /**
   * 获取所有省份
   */
  getAreaAllProvice: function getAreaAllProvice(data) {
    return (0, _http.GET)('/common/getAreaAllProvice', data);
  },
  /**
   * 获取检疫证类型
   */
  getQuarantineCertificateTypeList: function getQuarantineCertificateTypeList(data) {
    return (0, _http.GET)('/inspectionAndQuarantine/getQuarantineCertificateTypeList', data);
  },
  /**
   * 分页查询检验检疫信息
   */
  getQuarantinePageList: function getQuarantinePageList(data) {
    return (0, _http.POST)('/inspectionAndQuarantine/pageList', data);
  },
  /**
   * 新增检验检疫数据
   */
  addQuarantine: function addQuarantine(data) {
    return (0, _http.POST)('/inspectionAndQuarantine/insert', data);
  },
  /**
   * 修改检验检疫数据
   */
  updateQuarantine: function updateQuarantine(data) {
    return (0, _http.POST)('/inspectionAndQuarantine/update', data);
  },
  /**
   * 删除检验检疫数据
   */
  deleteQuarantine: function deleteQuarantine(data) {
    return (0, _http.POST)('/inspectionAndQuarantine/delete', data);
  },
  /**
   * 查看检疫检疫详情
   */
  getQuarantineDetail: function getQuarantineDetail(data) {
    return (0, _http.GET)('/inspectionAndQuarantine/getDetailById', data);
  },
  /**
   * 批量导入检验检疫数据
   */
  importInspectionAndQuarantine: function importInspectionAndQuarantine(data) {
    return (0, _http.FILEPOST)('/inspectionAndQuarantine/importInspectionAndQuarantine', data);
  },
  /**
   * 分页查询肉猪出栏信息
   */
  getPigOfftakePageList: function getPigOfftakePageList(data) {
    return (0, _http.POST)('/pigSlaughterDistribute/pageList', data);
  },
  /**
   * 新增肉猪出栏数据
   */
  addPigOfftake: function addPigOfftake(data) {
    return (0, _http.POST)('/pigSlaughterDistribute/insert', data);
  },
  /**
   * 修改肉猪出栏数据
   */
  updatePigOfftake: function updatePigOfftake(data) {
    return (0, _http.POST)('/pigSlaughterDistribute/update', data);
  },
  /**
   * 删除肉猪出栏量分布
   */
  deletePigOfftake: function deletePigOfftake(data) {
    return (0, _http.POST)('/pigSlaughterDistribute/delete', data);
  },
  /**
   * 查看肉猪出栏详情
   */
  getPigOfftakeDetail: function getPigOfftakeDetail(data) {
    return (0, _http.GET)('/pigSlaughterDistribute/getDetailById', data);
  },
  /**
   * 批量导入肉猪出栏数据
   */
  importPigSlaughterDistribute: function importPigSlaughterDistribute(data) {
    return (0, _http.FILEPOST)('/pigSlaughterDistribute/importPigSlaughterDistribute', data);
  },
  /* 承保信息 */getInsuranceList: function getInsuranceList(data) {
    // 列表
    return (0, _http.POST)('/insurance/getPage', data);
  },
  addInsurance: function addInsurance(data) {
    // 新增
    return (0, _http.POST)('/insurance/add', data);
  },
  editInsurance: function editInsurance(data) {
    // 编辑
    return (0, _http.POST)('/insurance/edit', data);
  },
  deleteInsurance: function deleteInsurance(data) {
    // 删除
    return (0, _http.JSONGET)('/insurance/delete', data);
  },
  importInsurance: function importInsurance(data) {
    // 导入
    return (0, _http.FILEPOST)('/insurance/import', data);
  },
  /* 理赔信息 */getCompensationList: function getCompensationList(data) {
    // 列表
    return (0, _http.POST)('/compensation/getPage', data);
  },
  addCompensation: function addCompensation(data) {
    // 新增
    return (0, _http.POST)('/compensation/add', data);
  },
  editCompensation: function editCompensation(data) {
    // 编辑
    return (0, _http.POST)('/compensation/edit', data);
  },
  deleteCompensation: function deleteCompensation(data) {
    // 删除
    return (0, _http.JSONGET)('/compensation/delete', data);
  },
  importCompensation: function importCompensation(data) {
    // 导入
    return (0, _http.FILEPOST)('/compensation/import', data);
  },
  getIndexPicInfo: function getIndexPicInfo(data) {
    // 根据project_id查询首页轮播图
    return (0, _http.GET)('/business/message/getIndexPicInfo', data);
  },
  editIndexPicInfo: function editIndexPicInfo(data) {
    // 新增和修改首页轮播图
    return (0, _http.POST)('/business/message/editIndexPicInfo', data);
  },
  findPolicyMessageList: function findPolicyMessageList(data) {
    // 消息管理-分页查询资讯消息列表
    return (0, _http.POST)('/business/message/findPolicyMessageList', data);
  },
  editPolicyMessageInfo: function editPolicyMessageInfo(data) {
    // 消息管理-新增和修改消息
    return (0, _http.POST)('/business/message/editPolicyMessageInfo', data);
  },
  getPolicyMessageInfo: function getPolicyMessageInfo(data) {
    // 消息管理-根据id获取消息详情
    return (0, _http.GET)('/business/message/getPolicyMessageInfo', data);
  },
  // 需求供给分析说明
  getSupplyDescrList: function getSupplyDescrList(data) {
    // 分页查询
    return (0, _http.POST)('/demandSupplyAnalysisData/queryByPage', data);
  },
  getSupplyDescr: function getSupplyDescr(data) {
    // 通过主键查询单条数据
    return (0, _http.GET)('/demandSupplyAnalysisData/queryById', data);
  },
  addSupplyDescr: function addSupplyDescr(data) {
    // 新增数据
    return (0, _http.POST)('/demandSupplyAnalysisData/add', data);
  },
  editSupplyDescr: function editSupplyDescr(data) {
    // 编辑数据
    return (0, _http.POST)('/demandSupplyAnalysisData/edit', data);
  },
  delSupplyDescr: function delSupplyDescr(data) {
    // 删除数据
    return (0, _http.GET)('/demandSupplyAnalysisData/deleteById', data);
  },
  addInsuranceData: function addInsuranceData(data) {
    // 新增保险
    return (0, _http.POST)('/insuranceData/addInsuranceData', data);
  },
  getInsuranceDataList: function getInsuranceDataList(data) {
    // 获取保险列表
    return (0, _http.POST)('/insuranceData/getInsuranceDataList', data);
  },
  getlInsuranceDataInfo: function getlInsuranceDataInfo(data) {
    // 保险详情
    return (0, _http.GET)('/insuranceData/getlInsuranceDataInfo', data);
  },
  delInsuranceData: function delInsuranceData(data) {
    // 删除保险
    return (0, _http.GET)('/insuranceData/delInsuranceData', data);
  },
  updateInsuranceData: function updateInsuranceData(data) {
    // 修改保险
    return (0, _http.POST)('/insuranceData/updateInsuranceData', data);
  },
  // 同类产品交易地区分析
  addSimilarSaleData: function addSimilarSaleData(data) {
    // 新增同类产品交易地区分析
    return (0, _http.POST)('/similarSaleData/addSimilarSaleData', data);
  },
  getSimilarSaleDataList: function getSimilarSaleDataList(data) {
    // 获取同类产品交易地区分析列表
    return (0, _http.POST)('/similarSaleData/getSimilarSaleDataList', data);
  },
  getSimilarSaleDataInfo: function getSimilarSaleDataInfo(data) {
    // 同类产品交易地区分析详情
    return (0, _http.GET)('/similarSaleData/getSimilarSaleDataInfo', data);
  },
  delSimilarSaleData: function delSimilarSaleData(data) {
    // 删除同类产品交易地区分析 
    return (0, _http.GET)('/similarSaleData/delSimilarSaleData', data);
  },
  updateSimilarSaleData: function updateSimilarSaleData(data) {
    // 修改同类产品交易地区分析
    return (0, _http.POST)('/similarSaleData/updateSimilarSaleData', data);
  },
  // 病死鸡数量分布
  addDeadChickenData: function addDeadChickenData(data) {
    // 新增病死鸡数量分布
    return (0, _http.POST)('/deadChickenData/addDeadChickenData', data);
  },
  getDeadChickenDataList: function getDeadChickenDataList(data) {
    // 获取病死鸡数量分布列表
    return (0, _http.POST)('/deadChickenData/getDeadChickenDataList', data);
  },
  getDeadChickenDataInfo: function getDeadChickenDataInfo(data) {
    // 病死鸡数量分布详情
    return (0, _http.GET)('/deadChickenData/getDeadChickenDataInfo', data);
  },
  delDeadChickenData: function delDeadChickenData(data) {
    // 删除病死鸡数量分布 
    return (0, _http.GET)('/deadChickenData/delDeadChickenData', data);
  },
  updateDeadChickenData: function updateDeadChickenData(data) {
    // 修改病死鸡数量分布
    return (0, _http.POST)('/deadChickenData/updateDeadChickenData', data);
  },
  // 禽疾病分布
  addPoultryDisDistributionData: function addPoultryDisDistributionData(data) {
    // 新增禽疾病分布
    return (0, _http.POST)('/xhc/poultryDisDistribution/add', data);
  },
  getPoultryDisDistribution: function getPoultryDisDistribution(data) {
    // 获取禽疾病分布列表
    return (0, _http.POST)('/xhc/poultryDisDistribution/pageList', data);
  },
  getPoultryDisDistributionInfo: function getPoultryDisDistributionInfo(data) {
    // 禽疾病分布详情
    return (0, _http.GET)('/xhc/poultryDisDistribution/queryById', data);
  },
  delPoultryDisDistributionData: function delPoultryDisDistributionData(data) {
    // 删除禽疾病分布 
    return (0, _http.GET)('/xhc/poultryDisDistribution/delete', data);
  },
  updatePoultryDisDistributionData: function updatePoultryDisDistributionData(data) {
    // 修改禽疾病分布
    return (0, _http.POST)('/xhc/poultryDisDistribution/edit', data);
  },
  //杏花鸡品牌
  getXhcBrandList: function getXhcBrandList(data) {
    // 获取企业品牌列表
    return (0, _http.POST)('/xhc/brand/getXhcBrandList', data);
  },
  addXhcBrand: function addXhcBrand(data) {
    // 添加杏花鸡品牌
    return (0, _http.POST)('/xhc/brand/addXhcBrand', data);
  }
};