"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 基础管理 */
var _default = exports.default = {
  /**
   * 基础管理列表
   */
  querySchoolCourseUserCountList: function querySchoolCourseUserCountList(data) {
    return (0, _http.POST)('/schoolCourse/querySchoolCourseUserCountList', data);
  },
  /**
   * 基础管理列表——添加
   */
  schoolAdd: function schoolAdd(data) {
    return (0, _http.POST)('/schoolCourse/insert', data);
  },
  /**
   * 基础管理列表——编辑
   */
  schoolEdit: function schoolEdit(data) {
    return (0, _http.POST)('/schoolCourse/update', data);
  },
  /**
   * 基础管理列表——删除
   */
  schoolDel: function schoolDel(data) {
    return (0, _http.POST)("/schoolCourse/deleteById?id=".concat(data.id), data);
  }
};