"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
var _default = exports.default = {
  // 追溯码
  getBasicRetraceList: function getBasicRetraceList(data) {
    // 获取追溯码信息列表
    return (0, _http.POST)('/xhc/basicRetrace/getBasicRetraceList', data);
  },
  addBasicRetrace: function addBasicRetrace(data) {
    // 新增追溯码
    return (0, _http.POST)('/xhc/basicRetrace/addBasicRetrace', data);
  },
  updateBasicRetrace: function updateBasicRetrace(data) {
    // 修改追溯码
    return (0, _http.POST)('/xhc/basicRetrace/updateBasicRetrace', data);
  },
  getEnterpriseSelectList: function getEnterpriseSelectList(data) {
    // 获取企业下拉框列表
    return (0, _http.GET)('/xhc/enterpriseInfo/getEnterpriseSelectList', data);
  },
  deleteBasicRetrace: function deleteBasicRetrace(data) {
    // 删除追溯码
    return (0, _http.GET)('/xhc/basicRetrace/deleteBasicRetrace', data);
  },
  getBasicRetraceById: function getBasicRetraceById(data) {
    // 进入编辑追溯码信息
    return (0, _http.GET)('/xhc/basicRetrace/getBasicRetraceById', data);
  },
  getChickenEnterList: function getChickenEnterList(data) {
    // 获取小鸡入场信息列表
    return (0, _http.POST)('/xhc/chickenEnter/getChickenEnterList', data);
  },
  getChickenAntigenList: function getChickenAntigenList(data) {
    // 获取抗原检测信息列表
    return (0, _http.POST)('/xhc/chickenAntigen/getChickenAntigenList', data);
  },
  getChickenImmunityList: function getChickenImmunityList(data) {
    // 获取小鸡免疫信息列表
    return (0, _http.POST)('/xhc/chickenImmunity/getChickenImmunityList', data);
  },
  getChickenPharmacyList: function getChickenPharmacyList(data) {
    // 获取小鸡用药信息列表
    return (0, _http.POST)('/xhc/chickenPharmacy/getChickenPharmacyList', data);
  },
  getChickenFeedingList: function getChickenFeedingList(data) {
    // 获取小鸡喂养信息列表
    return (0, _http.POST)('/xhc/chickenFeeding/getChickenFeedingList', data);
  },
  deleteChickenEnter: function deleteChickenEnter(data) {
    // 删除入场
    return (0, _http.GET)('/xhc/chickenEnter/deleteChickenEnter', data);
  },
  deleteChickenAntigen: function deleteChickenAntigen(data) {
    // 删除抗原检测
    return (0, _http.GET)('/xhc/chickenAntigen/deleteChickenAntigen', data);
  },
  deleteChickenImmunity: function deleteChickenImmunity(data) {
    // 删除免疫
    return (0, _http.GET)('/xhc/chickenImmunity/deleteChickenImmunity', data);
  },
  deleteChickenPharmacy: function deleteChickenPharmacy(data) {
    // 删除用药
    return (0, _http.GET)('/xhc/chickenPharmacy/deleteChickenPharmacy', data);
  },
  deleteChickenFeeding: function deleteChickenFeeding(data) {
    // 删除喂养
    return (0, _http.GET)('/xhc/chickenFeeding/deleteChickenFeeding', data);
  },
  addChickenEnter: function addChickenEnter(data) {
    // 新增入场
    return (0, _http.POST)('/xhc/chickenEnter/addChickenEnter', data);
  },
  getChickenEnterById: function getChickenEnterById(data) {
    // 进入编辑入场信息
    return (0, _http.GET)('/xhc/chickenEnter/getChickenEnterById', data);
  },
  updateChickenEnter: function updateChickenEnter(data) {
    // 修改入场
    return (0, _http.POST)('/xhc/chickenEnter/updateChickenEnter', data);
  },
  addChickenAntigen: function addChickenAntigen(data) {
    // 新增抗原检测
    return (0, _http.POST)('/xhc/chickenAntigen/addChickenAntigen', data);
  },
  getChickenAntigenById: function getChickenAntigenById(data) {
    // 进入编辑抗原检测
    return (0, _http.GET)('/xhc/chickenAntigen/getChickenAntigenById', data);
  },
  updateChickenAntigen: function updateChickenAntigen(data) {
    // 修改抗原检测
    return (0, _http.POST)('/xhc/chickenAntigen/updateChickenAntigen', data);
  },
  addChickenImmunity: function addChickenImmunity(data) {
    // 新增免疫
    return (0, _http.POST)('/xhc/chickenImmunity/addChickenImmunity', data);
  },
  getChickenImmunityById: function getChickenImmunityById(data) {
    // 进入编辑免疫信息
    return (0, _http.GET)('/xhc/chickenImmunity/getChickenImmunityById', data);
  },
  updateChickenImmunity: function updateChickenImmunity(data) {
    // 修改免疫
    return (0, _http.POST)('/xhc/chickenImmunity/updateChickenImmunity', data);
  },
  addChickenPharmacy: function addChickenPharmacy(data) {
    // 新增用药
    return (0, _http.POST)('/xhc/chickenPharmacy/addChickenPharmacy', data);
  },
  getChickenPharmacyById: function getChickenPharmacyById(data) {
    // 进入编辑用药信息
    return (0, _http.GET)('/xhc/chickenPharmacy/getChickenPharmacyById', data);
  },
  updateChickenPharmacy: function updateChickenPharmacy(data) {
    // 修改用药
    return (0, _http.POST)('/xhc/chickenPharmacy/updateChickenPharmacy', data);
  },
  addChickenFeeding: function addChickenFeeding(data) {
    // 新增喂养
    return (0, _http.POST)('/xhc/chickenFeeding/addChickenFeeding', data);
  },
  getChickenFeedingById: function getChickenFeedingById(data) {
    // 进入编辑喂养信息
    return (0, _http.GET)('/xhc/chickenFeeding/getChickenFeedingById', data);
  },
  updateChickenFeeding: function updateChickenFeeding(data) {
    // 修改喂养
    return (0, _http.POST)('/xhc/chickenFeeding/updateChickenFeeding', data);
  },
  updateLogistics: function updateLogistics(data) {
    // 修改物流
    return (0, _http.POST)('/xhc/logisticsInfo/updateLogistics', data);
  },
  getLogisticsInfoById: function getLogisticsInfoById(data) {
    // 进入编辑物流信息
    return (0, _http.GET)('/xhc/logisticsInfo/getLogisticsInfoById', data);
  },
  getQuarantineInfoById: function getQuarantineInfoById(data) {
    // 进入编辑检疫信息
    return (0, _http.GET)('/xhc/quarantineInfo/getQuarantineInfoById', data);
  },
  updateQuarantine: function updateQuarantine(data) {
    // 修改检疫
    return (0, _http.POST)('/xhc/quarantineInfo/updateQuarantine', data);
  },
  getSlaughteringInfoById: function getSlaughteringInfoById(data) {
    // 进入编辑屠宰信息
    return (0, _http.GET)('/xhc/slaughteringInfo/getSlaughteringInfoById', data);
  },
  updateSlaughtering: function updateSlaughtering(data) {
    // 修改屠宰
    return (0, _http.POST)('/xhc/slaughteringInfo/updateSlaughtering', data);
  },
  getTagStatusList: function getTagStatusList(data) {
    // 追溯码详情页判断tag显示
    return (0, _http.GET)('/xhc/basicRetrace/getTagStatusList', data);
  },
  getLogisticsInfo: function getLogisticsInfo(data) {
    // 根据追溯码获取物流详情
    return (0, _http.GET)('/xhc/logisticsInfo/getLogisticsInfo', data);
  },
  getSlaughteringInfo: function getSlaughteringInfo(data) {
    // 根据追溯码获取屠宰详情
    return (0, _http.GET)('/xhc/slaughteringInfo/getSlaughteringInfo', data);
  },
  getQuarantineInfo: function getQuarantineInfo(data) {
    // 根据追溯码获取检疫详情
    return (0, _http.GET)('/xhc/quarantineInfo/getQuarantineInfo', data);
  }
};