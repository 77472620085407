"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleClipboard;
var _vue = _interopRequireDefault(require("vue"));
var _clipboard = _interopRequireDefault(require("clipboard"));
function clipboardSuccess() {
  _vue.default.prototype.$message({
    message: '复制成功！',
    type: 'success',
    duration: 1500
  });
}
function clipboardError() {
  _vue.default.prototype.$message({
    message: '复制失败！',
    type: 'error'
  });
}
function handleClipboard(event, _text) {
  var clipboard;
  if (_text) {
    clipboard = new _clipboard.default(event.target, {
      text: function text() {
        return _text;
      }
    });
  } else {
    clipboard = new _clipboard.default(event.target);
  }
  clipboard.on('success', function () {
    clipboardSuccess();
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    clipboardError();
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.onClick(event);
}