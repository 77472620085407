"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icons-container"
  }, [_vm._m(0), _c("div", {
    staticClass: "icons-wrapper"
  }, _vm._l(_vm.iconsMap, function (item) {
    return _c("div", {
      key: item,
      on: {
        click: function click($event) {
          _vm.handleClipboard(_vm.generateIconCode(item), $event);
        }
      }
    }, [_c("el-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(" " + _vm._s(_vm.generateIconCode(item)) + " ")]), _c("div", {
      staticClass: "icon-item"
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": item,
        "class-name": "disabled"
      }
    }), _c("span", [_vm._v(_vm._s(item))])], 1)])], 1);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "warn-content"
  }, [_c("a", {
    attrs: {
      href: "https://panjiachen.github.io/vue-element-admin-site/guide/advanced/icon.html",
      target: "_blank"
    }
  }, [_vm._v("Add and use ")])]);
}];
render._withStripped = true;