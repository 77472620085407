"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 管理后台成员管理 */
var _default = exports.default = {
  /**
   * 新增/修改成员
   */
  editOrAddUser: function editOrAddUser(data) {
    return (0, _http.POST)('/business/bgUser/editUser', data, 'none');
  },
  /**
   * 成员列表
  */
  getUserList: function getUserList(data) {
    return (0, _http.POST)('/business/bgUser/findList', data);
  },
  /**
  * 成员详情
  */
  getUserDetail: function getUserDetail(data) {
    return (0, _http.GET)('/business/bgUser/userDetail', data);
  },
  /**
   * 修改成员状态
  */
  updateStatus: function updateStatus(data) {
    return (0, _http.POST)('/business/bgUser/updateStatus', data);
  },
  /**
   * 删除成员
  */
  deleteUser: function deleteUser(data) {
    return (0, _http.GET)('/business/bgUser/deleteUser', data);
  },
  /**
   * 绑定OA账号
  */
  bindOaPhone: function bindOaPhone(data) {
    return (0, _http.POST)('/business/bgUser/bindOaPhone', data);
  },
  /**
   * 刷新用户审核状态(企联网)
  */
  refreshQLWState: function refreshQLWState(data) {
    return (0, _http.GET)('/business/bgUser/refreshAuditStatus', data);
  },
  // 获取政府部门
  getGovDepList: function getGovDepList(data) {
    return (0, _http.GET)('/business/bgUserGroup/getGovDepList', data);
  }
};