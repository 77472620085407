"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'evaluateManage',
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '课程名称',
        prop: 'courseTitle',
        minWidth: '100',
        width: null
      }, {
        title: '评论人',
        prop: 'reviewerName',
        minWidth: '',
        width: null
      }, {
        title: '评论时间',
        prop: 'updateTime',
        minWidth: '',
        width: null
      }, {
        title: '评论内容',
        prop: 'reviewContent',
        minWidth: '',
        width: null
      }, {
        title: '操作',
        prop: 'operation',
        minWidth: '100',
        width: ''
      }],
      tableData: [],
      total: 0,
      searchParams: {
        courseTitle: '',
        // 课程名称
        reviewerName: '',
        // 评论人姓名
        time: '',
        currentPage: 1,
        pageSize: 20
      },
      id: ''
    };
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {
        currentPage: this.searchParams.currentPage,
        pageSize: this.searchParams.pageSize,
        courseTitle: this.searchParams.courseTitle,
        reviewerName: this.searchParams.reviewerName,
        startTime: this.searchParams.time && this.searchParams.time[0] ? this.Moment(this.searchParams.time[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        endTime: this.searchParams.time && this.searchParams.time[1] ? this.Moment(this.searchParams.time[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
      };
      this.$api.evaluate.courseEvaluate(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this2 = this;
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664\u5417?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$api.evaluate.evaluateDel({
          id: obj.id
        }).then(function () {
          _this2.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this2.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      this.getDataList();
    },
    getTime: function getTime(val) {
      if (val) {
        return this.Moment(val).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
    },
    interSearch: function interSearch() {
      this.searchParams.currentPage = 1;
      this.getDataList(this.searchParams.currentPage);
    }
  }
};