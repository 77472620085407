"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  projectId: function projectId(state) {
    return state.user.projectId;
  },
  isSuperAdmin: function isSuperAdmin(state) {
    return state.user.isSuperAdmin;
  },
  token: function token(state) {
    return state.user.token;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  userName: function userName(state) {
    return state.user.userName;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  combox: function combox(state) {
    return state.combox;
  }
};
var _default = exports.default = getters;