"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _form = _interopRequireDefault(require("@/components/form"));
var _utils = require("@/utils");
var _default = exports.default = {
  name: 'classification',
  components: {
    formWrap: _form.default
  },
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '课程类型',
        prop: 'courseType',
        minWidth: '100',
        width: null
      }, {
        title: '课程数量',
        prop: 'courseCount',
        minWidth: '',
        width: null
      }, {
        title: '操作',
        prop: 'operation',
        minWidth: '100',
        width: ''
      }],
      tableData: [],
      total: 0,
      searchParams: {
        currentPage: 1,
        pageSize: 20
      },
      id: '',
      title: '添加'
    };
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {
        currentPage: this.searchParams.currentPage,
        pageSize: this.searchParams.pageSize,
        id: this.id
      };
      this.$api.course.courseClassification(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    /* 新增&编辑 */add: function add(type, obj) {
      var labelList = [{
        label: '课程类型',
        prop: 'courseType',
        type: 'input',
        required: true
      }];
      if (type === 'add') {
        this.title = '添加';
        this.$refs.form.showDialog(labelList);
      } else {
        this.title = '编辑';
        this.$refs.form.showForm(obj, labelList);
      }
    },
    submit: function submit(form) {
      var _this2 = this;
      if (form.id) {
        var param = {
          courseType: form.courseType,
          id: form.id
        };
        this.$api.course.courseTypeEdit(param).then(function (res) {
          if (res.status.code === 200) {
            _this2.$message.success('编辑成功');
            _this2.$refs.form.cancel();
            _this2.getDataList();
          }
        });
      } else {
        var _param = {
          courseType: form.courseType,
          createUser: (0, _utils.getSession)('userId')
        };
        this.$api.course.courseTypeAdd(_param).then(function (res) {
          if (res.status.code === 200) {
            _this2.$message.success('添加成功');
            _this2.$refs.form.cancel();
            _this2.getDataList();
          }
        });
      }
    },
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this3 = this;
      if (obj.courseCount) {
        this.$message.warning('该类型下有课程，不能删除！');
        return;
      }
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664".concat(obj.courseType, "\u8FD9\u4E2A\u7C7B\u578B\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.$api.course.courseTypeDel({
          id: obj.id
        }).then(function () {
          _this3.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this3.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      this.getDataList();
    }
  }
};