"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _form = _interopRequireDefault(require("@/components/form"));
var _utils = require("@/utils");
var _default = exports.default = {
  name: 'courseManage',
  components: {
    formWrap: _form.default
  },
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '课程名称',
        prop: 'courseTitle',
        minWidth: '100',
        width: null
      }, {
        title: '课程分类',
        prop: 'courseType',
        minWidth: '',
        width: null
      }, {
        title: '上传时间',
        prop: 'updateTime',
        minWidth: '',
        width: null
      }, {
        title: '操作',
        prop: 'operation',
        minWidth: '100',
        width: ''
      }],
      tableData: [],
      total: 0,
      searchParams: {
        currentPage: 1,
        pageSize: 20
      },
      id: '',
      classificationSelect: [],
      title: '添加课程'
    };
  },
  mounted: function mounted() {
    this.getDataList();
    // 获取课程分类
    this.getCourseClass();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {
        currentPage: this.searchParams.currentPage,
        pageSize: this.searchParams.pageSize,
        id: this.id
      };
      this.$api.course.courseList(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    getCourseClass: function getCourseClass() {
      var _this2 = this;
      var param = {
        currentPage: 1,
        pageSize: 999
      };
      this.$api.course.courseClassification(param).then(function (res) {
        _this2.classificationSelect = res.data || [];
        if (_this2.classificationSelect.length) {
          _this2.classificationSelect.forEach(function (item) {
            _this2.$set(item, 'label', item.courseType);
            _this2.$set(item, 'value', item.id);
          });
        }
      });
    },
    /**
    * 新增
    **/
    add: function add() {
      var labelList = [{
        label: '课程名称',
        prop: 'courseTitle',
        type: 'input',
        required: true
      }, {
        label: '课程分类',
        prop: 'courseTypeId',
        type: 'select',
        required: true,
        select: this.classificationSelect
      }];
      this.$refs.form.showDialog(labelList);
    },
    /* 编辑 */edit: function edit(type, obj) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          courseId: obj && obj.id ? obj.id : '',
          type: type
        }
      });
    },
    submit: function submit(form) {
      var _this3 = this;
      var param = {
        courseTitle: form.courseTitle,
        courseTypeId: form.courseTypeId,
        createUser: (0, _utils.getSession)('userId'),
        courseImgUrl: '',
        courseIntroduction: '',
        courseContent: '',
        sort: '',
        remark: '',
        available: 0
      };
      this.$api.course.courseOverviewAdd(param).then(function (res) {
        if (res.status.code === 200) {
          _this3.$message.success('添加成功');
          _this3.$refs.form.cancel();
          _this3.getDataList();
        }
      });
    },
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this4 = this;
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664".concat(obj.courseTitle, "\u8FD9\u4E2A\u8BFE\u7A0B?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.$api.course.courseOverviewDel({
          id: obj.id
        }).then(function () {
          _this4.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this4.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      // this.searchParams.currentPage = 1
      this.getDataList();
    },
    getTime: function getTime(val) {
      if (val) {
        return this.Moment(val).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
    },
    // 上架
    listing: function listing(row) {
      var _this5 = this;
      var param = {
        id: row.id,
        available: 1
      };
      this.$api.course.courseUpdate(param).then(function (res) {
        if (res.status.code === 200) {
          _this5.$message.success('上架成功');
          _this5.getDataList();
        }
      });
    },
    // 下架
    delist: function delist(row) {
      var _this6 = this;
      this.$confirm("\u786E\u5B9A\u8981\u4E0B\u67B6\u5417?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          id: row.id,
          available: 0
        };
        _this6.$api.course.courseUpdate(param).then(function (res) {
          if (res.status.code === 200) {
            _this6.$message.success('下架成功');
            _this6.getDataList();
          }
        });
      }).catch(function () {});
    }
  }
};