"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [false ? _c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }) : _vm._e(), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [_c("screenfull", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "screenfull"
    }
  })] : _vm._e(), _vm.hasPermission ? _c("el-badge", {
    staticClass: "right-menu-item",
    attrs: {
      value: ""
    }
  }, [_c("router-link", {
    attrs: {
      to: "/warnManage/warnList"
    }
  }, [_c("el-button", {
    attrs: {
      size: "small"
    }
  }, [_c("i", {
    staticClass: "el-icon-bell mr-5"
  }), _vm._v("预警信息")])], 1)], 1) : _vm._e(), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("span", {
    staticClass: "el-dropdown-link user-menu-item"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "me"
    }
  }), _vm._v(" " + _vm._s(_vm.userName) + " "), _c("i", {
    staticClass: "el-icon-caret-bottom el-icon--right"
  })], 1)]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("span", {
    on: {
      click: _vm.showAccountInfo
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "account"
    }
  }), _vm._v("修改密码")], 1)]), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.logout
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "out"
    }
  }), _vm._v("退出登录")], 1)])], 1)], 1)], 2), _vm.showInfoDialog ? _c("change-account-pwd", {
    key: _vm.dialogKey,
    attrs: {
      show: _vm.showInfoDialog
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showInfoDialog = $event;
      },
      close: function close($event) {
        _vm.showInfoDialog = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;