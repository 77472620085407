"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/typeof.js"));
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _layout = _interopRequireDefault(require("@/views/layout"));
var _index = _interopRequireDefault(require("@/views/layout/index2"));
var _index2 = _interopRequireDefault(require("@/views/basicsManage/index"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var fzslaughterData = {
  path: '/slaughterData',
  name: 'slaughterDataManagement',
  component: _layout.default,
  meta: {
    title: '杏花鸡屠宰数据',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'slaughterData',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '杏花鸡屠宰数据',
      noCache: true
    }
  }]
};
var harmlessData = {
  path: '/harmlessData',
  name: 'harmlessDataManagement',
  component: _layout.default,
  meta: {
    title: '无害化处理数据',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'harmlessData',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '无害化处理数据',
      noCache: true
    }
  }]
};
var similarSaleData = {
  path: '/similarSale',
  name: 'similarSaleManagement',
  component: _layout.default,
  meta: {
    title: '同类产品交易地区分析',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'similarSale',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '同类产品交易地区分析',
      noCache: true
    }
  }]
};
var deadChickenData = {
  path: '/deadChicken',
  name: 'deadChickenManagement',
  component: _layout.default,
  meta: {
    title: '病死鸡数量分布',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'deadChicken',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '病死鸡数量分布',
      noCache: true
    }
  }]
};
var poultryDisDistributionData = {
  path: '/poultryDisDistribution',
  name: 'poultryDisDistributionManagement',
  component: _layout.default,
  meta: {
    title: '禽疾病分布',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'poultryDisDistribution',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '禽疾病分布',
      noCache: true
    }
  }]
};
var insuranceTypeData = {
  path: '/insuranceType',
  name: 'insuranceTypeManagement',
  component: _layout.default,
  meta: {
    title: '保险类型',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'insuranceType',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '保险类型',
      noCache: true
    }
  }]
};
var brandData = {
  path: '/brand',
  name: 'brandManagement',
  component: _layout.default,
  meta: {
    title: '杏花鸡品牌',
    noCache: true,
    breadcrumb: false
  },
  children: [{
    path: 'index',
    name: 'brand',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '杏花鸡品牌',
      noCache: true
    }
  }]
};
function addDataRoute(routes, roles) {
  var res = [],
    roleChild = [];
  var findItem = roles.find(function (el) {
    return el.menuUrl === 'dataManagement';
  });
  if (findItem) {
    roles.map(function (el) {
      if (findItem.id === el.parentId) roleChild.push(el);
    });
    roleChild.forEach(function (role) {
      var child = [];
      // 抚州项目，添加无害化处理/屠宰企业数据，判断是否有权限
      var menuPermission = (0, _utils.getSession)('menuPermission');
      if (role.menuUrl === 'importAndExportAnalysis') {
        var item = menuPermission.find(function (el) {
          return el.menuUrl === 'similarSale';
        });
        if (item) {
          similarSaleData.children[0].meta.rights = item.rights;
          child.push(similarSaleData);
        }
      }
      if (role.menuUrl === 'harmlessTreatmentTwo') {
        var _item = menuPermission.find(function (el) {
          return el.menuUrl === 'deadChicken';
        });
        if (_item) {
          deadChickenData.children[0].meta.rights = _item.rights;
          child.push(deadChickenData);
        }
      }
      if (role.menuUrl === 'bg_investment') {
        var _item2 = menuPermission.find(function (el) {
          return el.menuUrl === 'poultryDisDistribution';
        });
        if (_item2) {
          poultryDisDistributionData.children[0].meta.rights = _item2.rights;
          child.push(poultryDisDistributionData);
        }
      }
      if (role.menuUrl === 'financial_three') {
        var _item3 = menuPermission.find(function (el) {
          return el.menuUrl === 'insuranceType';
        });
        if (_item3) {
          insuranceTypeData.children[0].meta.rights = _item3.rights;
          child.push(insuranceTypeData);
        }
      }
      if (role.projectId === 2 && role.menuUrl === 'bg_harmlessTreatmentAnalysis') {
        var _item4 = menuPermission.find(function (el) {
          return el.menuUrl === 'harmlessData';
        });
        if (_item4) child.push(harmlessData);
      }
      if (role.projectId === 2 && role.menuUrl === 'bg_pigSlaughter') {
        var _item5 = menuPermission.find(function (el) {
          return el.menuUrl === 'slaughterData';
        });
        if (_item5) child.push(fzslaughterData);
      }
      routes.forEach(function (route) {
        if (route.status === 0 && route.parentId === role.id) {
          var obj = {
            path: route.menuUrl,
            name: role.menuPermission,
            component: _layout.default,
            meta: {
              title: route.menuName,
              breadcrumb: false
            },
            redirect: "".concat(route.menuUrl, "/index"),
            needAuth: false,
            children: [{
              path: 'index',
              name: route.menuPermission + 'index',
              component: _index2.default,
              meta: {
                title: route.menuName,
                menuId: route.menuId,
                rights: route.rights
              }
            }]
          };
          child.push(obj);
        }
      });
      if (role.menuUrl === 'bg_industrial') {
        var _item6 = menuPermission.find(function (el) {
          return el.menuUrl === 'brand';
        });
        if (_item6) {
          brandData.children[0].meta.rights = _item6.rights;
          child.push(brandData);
        }
      }
      var tmp = {
        path: role.menuUrl,
        name: role.menuUrl,
        component: _index.default,
        meta: {
          title: role.menuName,
          breadcrumb: false
        },
        needAuth: false,
        children: child
      };
      child.length > 0 && res.push(tmp);
    });
  }
  return res;
}
var _default = exports.default = addDataRoute;