"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _default = exports.default = {
  name: 'role',
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '角色权限',
        prop: 'roleName',
        minWidth: '100',
        width: null
      }, {
        title: '成员数量',
        prop: 'userCount',
        minWidth: '',
        width: null
      }
      // { title: '操作', prop: 'operation', minWidth: '100', width: '' }
      ],
      tableData: [],
      total: 0,
      searchParams: {
        currentPage: 1,
        pageSize: 20
      },
      id: ''
    };
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {};
      this.$api.role.getUserCountByRole(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    /* 新增&编辑 */add: function add(url, obj) {},
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this2 = this;
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664".concat(obj.name, "\u8FD9\u4E2A\u4F01\u4E1A\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$api.data.operateEnterpriseInfo({
          enterpriseId: obj.enterpriseId,
          operateType: 'DELETE'
        }).then(function () {
          _this2.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this2.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      this.searchParams.currentPage = 1;
      this.getDataList();
    }
  }
};