"use strict";

var _interopRequireDefault = require("E:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _business = _interopRequireDefault(require("@/api/modules/business"));
var _auth = require("@/utils/auth");
var _utils = require("@/utils");
var _jsencrypt = require("jsencrypt");
var _router = require("@/router");
var user = {
  state: {
    projectId: (0, _utils.getSession)('projectId'),
    isSuperAdmin: (0, _utils.getSession)('isSuperAdmin'),
    token: (0, _auth.getAuthorization)(),
    userId: (0, _utils.getSession)('userId'),
    userName: (0, _utils.getSession)('userName'),
    avatar: '',
    roles: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      (0, _auth.setAuthorization)(token);
      state.token = token;
    },
    SET_ID: function SET_ID(state, id) {
      state.userId = id;
      (0, _utils.setSession)('userId', id);
    },
    SET_NAME: function SET_NAME(state, name) {
      (0, _utils.setSession)('userName', name);
      state.userName = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PROJECTID: function SET_PROJECTID(state, projectId) {
      state.projectId = projectId;
    },
    SUPER_ADMIN: function SUPER_ADMIN(state, isSuperAdmin) {
      state.isSuperAdmin = isSuperAdmin;
    }
  },
  actions: {
    // 验证用户名和密码
    verifyUser: function verifyUser(_ref, userInfo) {
      var commit = _ref.commit;
      var loginName = userInfo.loginName,
        loginPwd = userInfo.loginPwd;
      var key = (0, _utils.publicKey)();
      var encryptor = new _jsencrypt.JSEncrypt();
      encryptor.setPublicKey(key);
      var encrypted = encryptor.encrypt(loginPwd);
      return new Promise(function (resolve, reject) {
        _business.default.checkUser({
          loginName: loginName,
          loginPwd: encrypted
        }).then(function (response) {
          var data = response.data;
          (0, _utils.setSession)('token', data.token);
          commit('SET_TOKEN', data.token);
          // commit('SET_NAME', data.userName)
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 账号密码登录
    login: function login(_ref2, checkInfo) {
      var commit = _ref2.commit;
      var loginName = checkInfo.loginName,
        loginPwd = checkInfo.loginPwd,
        ip = checkInfo.ip;
      var key = (0, _utils.publicKey)();
      var encryptor = new _jsencrypt.JSEncrypt();
      encryptor.setPublicKey(key);
      var encrypted = encryptor.encrypt(loginPwd);
      return new Promise(function (resolve, reject) {
        _business.default.submitLogin({
          loginPwd: encrypted,
          loginName: loginName,
          ip: ip
        }).then(function (response) {
          var data = response.data;
          (0, _utils.setSession)('token', data.token);
          (0, _utils.setSession)('userId', data.userId);
          (0, _utils.setSession)('userName', data.userName);
          (0, _utils.setSession)('roleName', data.roleName);
          (0, _utils.setSession)('roleId', data.roleId);
          (0, _utils.setSession)('projectId', data.projectId);
          (0, _utils.setSession)('isSuperAdmin', String(data.isSuperAdmin));
          (0, _utils.setSession)('userProjectIdList', data.projectIdList);
          commit('SET_TOKEN', data.token);
          commit('SET_NAME', data.userName);
          commit('SET_ID', data.userId);
          commit('SET_ROLES', data.roleName);
          commit('SET_PROJECTID', data.projectId);
          commit('SUPER_ADMIN', String(data.isSuperAdmin));
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 短信登录
    smsLogin: function smsLogin(_ref3, checkInfo) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        _business.default.smsLogin(checkInfo).then(function (response) {
          var data = response.data;
          (0, _utils.setSession)('token', data.token);
          (0, _utils.setSession)('userId', data.userId);
          (0, _utils.setSession)('userName', data.userName);
          (0, _utils.setSession)('roleName', data.roleName);
          (0, _utils.setSession)('roleId', data.roleId);
          (0, _utils.setSession)('projectId', data.projectId);
          (0, _utils.setSession)('isSuperAdmin', String(data.isSuperAdmin));
          (0, _utils.setSession)('userProjectIdList', data.projectIdList);
          commit('SET_TOKEN', data.token);
          commit('SET_NAME', data.userName);
          commit('SET_ID', data.userId);
          commit('SET_ROLES', data.roleName);
          commit('SET_PROJECTID', data.projectId);
          commit('SUPER_ADMIN', String(data.isSuperAdmin));
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    logout: function logout(_ref4) {
      var dispatch = _ref4.dispatch,
        commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        commit('SET_NAME', '');
        commit('SET_ID', '');
        commit('SET_PROJECTID', '');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('roleName');
        localStorage.removeItem('roleId');
        localStorage.removeItem('projectId');
        localStorage.removeItem('isSuperAdmin');
        localStorage.removeItem('permission');
        localStorage.removeItem('menuPermission');
        (0, _auth.removeToken)();
        dispatch('tagsView/delAllViews');
        dispatch('clearRoutes').then(function () {
          (0, _router.resetRouter)();
          resolve();
        });
      });
    },
    changeProject: function changeProject(_ref5, project) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        (0, _utils.setSession)('projectId', project);
        commit('SET_PROJECTID', project);
        resolve();
      });
    }
  }
};
var _default = exports.default = user;