"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.test = exports.TEXT_MESSAGE = exports.REG = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var REG = exports.REG = {
  nc: /^[\u4e00-\u9fa5_a-zA-Z0-9]*$/,
  // 昵称 由3 ~ 12 位字符的汉字、字母、数字、下划线组成，一个汉字占两个字符
  real_name: /^[\u4e00-\u9fa5a-zA-Z]{2,20}$/,
  // 人名 由汉字、字母组成
  account: /^[a-zA-Z0-9][a-zA-Z0-9_]{3,11}$/,
  // 用户名由4-12位字母、数字、下划线组成，且第一位不能是下划线
  passWord: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/,
  // 密码由6~20位数字+字母、特殊字符组成，且不能为纯数字、字母、特殊字符
  confirmPassWord: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/,
  payPassword: /^\d{4}$/,
  // 支付密码  由4位纯数字组成
  code: /^[a-zA-Z0-9]+$/,
  // code
  capcha: /^[a-zA-Z0-9]+$/,
  // capcha
  describe: /^[\u4e00-\u9fa5a-zA-Z0-9_]*$/,
  // 描述 由汉字、字母组成  1~30 位字符组成 一个汉字占两字符
  word: /^[\u4e00-\u9fa5\w\s]+$/,
  // 文字，textarea
  money: /^[1-9]\d*(\.\d{1,3})?$|^0(\.\d{1,2})?$/,
  // 金钱 整数 或 1~3位的小数 或 0
  cardNo: /^\d{15,20}$/,
  // 卡号 由 15~20 位纯数字组成
  cart_no: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
  // 身份证号
  integer: /^-?\d+$/,
  // 整数
  age: /^[1-9][0-9]?$/,
  // 年龄
  mobile: /^1\d{10}$/,
  // 手机号码 /^1(3|4|5|7|8)\d{9}$/,
  phone: /^1\d{10}$/,
  // 手机号码 /^1(3|4|5|7|8)\d{9}$/,
  qq: /^\d{4,11}$/,
  // QQ号码
  ballnum: /^\d{1,2}$/,
  // 球号
  email: /^[A-Za-z0-9_-]+@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,10}$/,
  // 邮箱 /^[A-Za-z0-9_-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.(com|com.cn|net|msn.cn))+$/
  provinceId: /^[a-zA-Z0-9]{7,21}$/,
  Disabletag: /^(?!<\/?[^>]*>).{1,255}$/g,
  Price: /^\d+$/,
  birth_date: /^((?:19|20)\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  address: /^[\u4e00-\u9fa5a-zA-Z\d]{2,150}$/,
  agent: /^[\u4e00-\u9fa5a-zA-Z]{2,20}$/,
  currency: /.*/,
  country: /.*/,
  contact: /^[\u4e00-\u9fa5a-zA-Z]{2,20}$/,
  chinese: /^[\u4e00-\u9fa5]+$/,
  // 汉字
  SN: /^[a-zA-Z0-9-]{2,40}$/,
  SN_env: /^\d{15}$/,
  // 环控sn是15位纯数字
  SN_feed: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{12}$/,
  // 惠农sn是12位，可以是数字和字母的组合
  earNum: /^[a-zA-Z0-9-]{2,40}$/,
  number: /^[0-9]{1,29}$/,
  IP: /^(?=(\b|\D))(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))(?=(\b|\D))$/,
  decimals: /^\d+$|^\d*\.\d+$/g,
  creditCode: /^[A-Za-z0-9]+$/,
  //数字和字母的组合
  decimalpoint: /^\d*\.\d{1,7}$/g,
  //小数点
  decimal2: /^\d+(\.\d{1,2})?$/ //小数点2位
};
var TEXT_MESSAGE = exports.TEXT_MESSAGE = {
  account: '用户名由4-12位字母,数字,下划线组成,且第一位不能是下划线',
  passWord: '密码6-20位,至少包含数字和字母',
  againPassWord: ' ',
  real_name: '真实姓名格式不对',
  email: '邮箱格式不正确',
  qq: 'qq号码为4-11位的纯数字',
  mobile: 'SIM卡号必须是11位数字，且有效号码',
  phone: '手机号必须是11位数字，且有效号码',
  cart_no: '请输入正确的身份证号码',
  nc: '请输入正确的昵称',
  payPassword: '支付密码由4位纯数字组成, 默认1234',
  birth_date: '请选择您的出生年月',
  birthday: '请选择您的出生年月',
  address: '家庭住址',
  agent: '代理人',
  currency: '币种',
  country: '国家',
  contact: '联系人,由汉字,字母组成',
  cardNo: '卡号 由 15~20 位纯数字组成',
  money: '金钱格式由整数或者1-3位的小数或0组成',
  chinese: '字符为中文',
  SN: 'SN码由2-40位大小写字母和数字组成',
  SN_env: 'SN码是15位数字',
  SN_feed: 'SN码是12位，由数字和字母组合',
  earNum: '耳号码由2-40位大小写字母和数字组成',
  number: '输入的格式为2到30位的数字',
  IP: 'IP格式输入不正确',
  integer: '请输入整数',
  decimals: '请输入整数或者小数',
  creditCode: '由数字,字母组合',
  decimalpoint: '请输入小数点,且小数点不能超出7位',
  decimal2: '仅支持小数点后2位'
};
var test = exports.test = {};
Object.keys(REG).forEach(function (i) {
  if (TEXT_MESSAGE[i]) {
    test[i] = {
      test: function test(str) {
        return REG[i].test(str);
      },
      text: TEXT_MESSAGE[i]
    };
  }
});